import { Box, Button, Container as MuiContainer, Theme } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { config } from '../theme'
import { ReactNode, useContext } from 'react'
import { LayoutContext } from '..'
import Breadcrumbs from '../Breadcrumbs'
import GlobalContext from '../Providers/GlobalContext'
import { ContentLoader, ContentError } from '../ContentLoading'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { resetCaches } from '@apollo/client'

const Container = ({ children }: { children: ReactNode }): JSX.Element => {
  const { logout } = useAuth0()
  const { t } = useTranslation()
  const { pageLoading, pageError, currentOrganisation, organisations, user, role } =
    useContext(GlobalContext)
  const { isMobile, drawerOpen } = useContext(LayoutContext)
  const theme = useTheme()

  const renderContent = () => {
    if (pageLoading) {
      return <ContentLoader />
    }

    if (!role) {
      return (
        <ContentError message={t('styra.message.missing_role')}>
          <Box pt={2}>
            <Button
              sx={{ borderRadius: 999, px: 8 }}
              size="large"
              variant="contained"
              color="error"
              onClick={() => {
                localStorage.clear()
                sessionStorage.clear()
                resetCaches() //reset apollo caches
                logout(
                  window?.location?.origin
                    ? {
                        logoutParams: { returnTo: window.location.origin },
                        clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENTID || '',
                      }
                    : {}
                )
              }}
            >
              {t('general.log_out')}
            </Button>
          </Box>
        </ContentError>
      )
    }

    if (pageError || !user) {
      return <ContentError />
    }

    if (!organisations.length) {
      return <ContentError message={t('styra.message.no_organisation')} />
    }

    if (!currentOrganisation) {
      return <ContentError message={t('styra.message.select_organisation')} />
    }

    return children
  }

  return (
    <MuiContainer
      component="main"
      disableGutters={true}
      sx={styles(theme, drawerOpen, isMobile)}
      maxWidth={false}
    >
      <Breadcrumbs />
      {renderContent()}
    </MuiContainer>
  )
}

export default Container

export const UnauthorizedContainer = ({ children }: { children: ReactNode }): JSX.Element => {
  const { isMobile, drawerOpen } = useContext(LayoutContext)
  const theme = useTheme()

  return (
    <MuiContainer
      component="main"
      disableGutters={true}
      sx={styles(theme, drawerOpen, isMobile)}
      maxWidth={false}
    >
      {children}
    </MuiContainer>
  )
}

const styles = (theme: Theme, open: boolean, isMobile: boolean) => ({
  minHeight: '100dvh',
  backgroundColor: '#F8F8FA',
  padding: 3,
  paddingTop: 10,
  paddingBottom: 0,
  position: 'relative',
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: isMobile ? 0 : `-${config.drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
})
