import { useEffect } from 'react'
import {
  ApplicationInsights,
  SeverityLevel,
  IMetricTelemetry,
} from '@microsoft/applicationinsights-web'
import {
  ReactPlugin,
  withAITracking as aiWithAITracking,
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js'
import { dateNow } from '@microsoft/applicationinsights-core-js'
import * as cookies from 'react-cookies'
import { UnauthorizedLayout } from 'src/layout'
import { ContentError as ErrorMessage } from 'src/layout/ContentLoading'

let reactPlugin: ReactPlugin

const cookiesDisabled = () => {
  const consentCookie = cookies.load('cookieConsent')
  if (consentCookie === 'accepted') {
    return false // Do send telemetry
  }
  return true // Do not send the telemetry
}

let appInsights: ApplicationInsights | null = null
export const aiTrackingEnabled =
  (process.env.NEXT_PUBLIC_APPLICATION_INSIGHTS_CONNECTION_STRING || '') !== ''

// Configure AI based on environment variable, if nothing set then don't intialise
if (aiTrackingEnabled) {
  globalThis.console.log(
    `Initialising Application Insights ${
      process.env.NEXT_PUBLIC_APPLICATION_INSIGHTS_CONNECTION_STRING?.substring(19, 19 + 8) ||
      'nokey'
    } `
  )

  reactPlugin = new ReactPlugin()
  appInsights = new ApplicationInsights({
    config: {
      disableCookiesUsage: cookiesDisabled(),
      isStorageUseDisabled: cookiesDisabled(),
      connectionString: process.env.NEXT_PUBLIC_APPLICATION_INSIGHTS_CONNECTION_STRING,
      extensions: [reactPlugin],
      isBrowserLinkTrackingEnabled: true,
      loggingLevelTelemetry: 2,
      enableAutoRouteTracking: true,
      enableCorsCorrelation: true,
      correlationHeaderDomains: [
        '^(styra|talva).*.azurewebsites.net$',
        'nemlia.com$',
        ...(process.env.NEXT_PUBLIC_API_ENVIRONMENT !== 'production' ? ['localhost'] : []),
      ],
      correlationHeaderExcludedDomains: ['auth.nemlia.com$'],
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      enableAjaxPerfTracking: true,
      enableAjaxErrorStatusText: true,
    },
  })
  appInsights.loadAppInsights()

  appInsights.addDependencyInitializer((details: any) => {
    // Supress normal hotupdate calls
    if (details.item.target?.match(/.webpack.hot-update.json$/) && details.item.success) {
      return false
    }
  })

  type oldFunction = (...data: any[]) => void
  //
  // Overload the console and redirect to AI
  globalThis.rawconsole = globalThis.console
  const console = (function (oldCons) {
    const logReplacer =
      (old: oldFunction, level: SeverityLevel) =>
      (...text: any[]) => {
        old.apply(oldCons, text)
        if (level !== SeverityLevel.Error) {
          appInsights?.trackTrace({
            message: replaceStr(text),
            severityLevel: level,
          })
        } else {
          appInsights?.trackException({
            exception: isError(text?.[0] || 'no args')
              ? text[0]
              : new Error(text?.[0] || 'no args to console.error'),
            severityLevel: level,
          })
        }
      }
    return {
      ...oldCons,
      log: logReplacer(oldCons.log, SeverityLevel.Verbose),
      error: logReplacer(oldCons.error, SeverityLevel.Error),
      warn: logReplacer(oldCons.warn, SeverityLevel.Warning),
      info: logReplacer(oldCons.info, SeverityLevel.Information),
    }
  })(globalThis.console)
  globalThis.console = console
}

export function setCookiesEnabled(enabled: boolean): void {
  appInsights.getCookieMgr().setEnabled(enabled)
  appInsights.config.isStorageUseDisabled = !enabled
}

// Set the user
export function setAuthenticatedUser(id: string): void {
  if (appInsights) {
    const validatedId = id.replace(/[,;=| ]+/g, '_')
    appInsights.setAuthenticatedUserContext(validatedId)
  }
}

// Allow configuring of initialiser
export const addDependencyInitializer = appInsights
  ? appInsights.addDependencyInitializer
  : () => undefined

// Helper function to log an exception
export function exception(error: string | Error, properties: Record<string, unknown> = {}) {
  if (appInsights) {
    appInsights.trackException(
      {
        exception: isError(error) ? error : new Error(error || 'no exception provided'),
      },
      {
        ...properties,
      }
    )
  }
  ;(globalThis.rawconsole ?? globalThis.console).error(
    new Date().toISOString(),
    'ERROR',
    error,
    JSON.stringify(properties)
  )
}

export function trace(message: string, properties: Record<string, unknown> = {}) {
  appInsights?.trackTrace(
    {
      message: message,
    },
    {
      ...properties,
    }
  )
  ;(globalThis.rawconsole ?? globalThis.console).log(
    new Date().toISOString(),
    message,
    JSON.stringify(properties)
  )
}

// Create the container and information required to implement ApplicationInsights on a page
// this is only used if AI is enabled
const AppInsights = ({ children }: { children: any }) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={() => (
          <UnauthorizedLayout>
            <ErrorMessage
              title="We're sorry, an error occurred. Please try to reload page or contact support."
              message=""
            />
          </UnauthorizedLayout>
        )}
        appInsights={reactPlugin}
      >
        {children}
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  )
}

// To instrument various React components usage tracking, apply the `withAITracking` higher-order
// component function.
export function withAITracking(element: any) {
  return appInsights ? aiWithAITracking(reactPlugin, element) : element
}

// And export our own tracking wrapper to setup the application
export default withAITracking(
  appInsights ? AppInsights : ({ children }: { children: any }) => children
)

//
// Helper functions for simplifying the console log outputs for
// transfer into application insights
function deObject(placeholders: any[]) {
  return placeholders.map((a) => (typeof a == 'string' ? a : safeStringify(a)))
}

function safeStringify(a) {
  try {
    return JSON.stringify(a)
  } catch (e) {
    return String(a)
  }
}

function replaceStr(placeholders: any[]) {
  // 1. handle substitutions without discarding
  const str = placeholders.shift()
  const replaced =
    typeof str == 'string'
      ? str.replace(/%([sfdioO])/g, (m: string) => {
          if (m === 'o' || m === 'O') {
            return safeStringify(placeholders.shift())
          }
          return placeholders.shift()
        })
      : safeStringify(str)
  // then join any remaining data converting objects if needed
  return [replaced, ...deObject(placeholders)].join(' ')
}

function isError(obj: any): obj is Error {
  return obj && Object.prototype.toString.call(obj) === '[object Error]'
}

// If you want to track the time a component is mounted this can be used
// anything passed in as additional properties will be passed to AI
// for further work
// you probably want to useComponentTracking() instead
export function recordComponent(
  name: string,
  engagementTime: number,
  additionalProperties: Record<string, string> = {}
) {
  const metricData: IMetricTelemetry = {
    average: engagementTime,
    name: 'React Component Engaged Time (seconds)',
    sampleCount: 1,
  }

  additionalProperties['Component Name'] = name
  reactPlugin?.trackMetric(metricData, additionalProperties)
}

// hook to simplify component tracking
export function useComponentTracking(
  name: string,
  additionalProperties: Record<string, string> = {}
) {
  useEffect(() => {
    const startTime = dateNow()
    return () => {
      const stopTime = dateNow()
      const engagementTime = stopTime - startTime
      recordComponent(name, engagementTime, additionalProperties)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
