import { useContext, useRef, useState } from 'react'
import { resetCaches } from '@apollo/client'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  ClickAwayListener,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Popper,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import Fade from '@mui/material/Fade'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import { useAuth0 } from '@auth0/auth0-react'
import { LayoutContext } from '../../..'
import { useTranslation } from 'react-i18next'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import { useUpdateUserLanguage } from '../../../../operations/mutations/updateUserLanguage'
import { useSnackbar } from 'notistack'
import { ta_user_user_language } from '@nemlia/hasura-schema'
import GlobalContext from 'src/layout/Providers/GlobalContext'

const Profile = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { user, role, refetch } = useContext(GlobalContext)
  const { isMobile } = useContext(LayoutContext)
  const { logout } = useAuth0()
  const theme = useTheme()
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [selectOpen, setSelectOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const { mutate: updateLanguage } = useUpdateUserLanguage({
    id: '',
    user_language: '',
  })

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (selectOpen || (anchorRef.current && anchorRef.current.contains(event.target))) {
      return
    }
    setOpen(false)
  }

  const handleLanguageChange = async (e: ta_user_user_language) => {
    setLoading(true)
    await updateLanguage({ variables: { id: user.id, user_language: e } })
      .then(async () => {
        await refetch.user()
      })
      .catch(() => {
        enqueueSnackbar(t('styra.message.error_general'), {
          variant: 'error',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const iconBackColorOpen = 'grey.300'

  return (
    <Box sx={{ flexShrink: 0 }}>
      <ButtonBase
        sx={{
          p: 0.5,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: 'secondary.lighter' },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={1.5} alignItems="center">
          <Avatar
            alt="profile user"
            sx={{
              width: 32,
              height: 32,
              bgcolor: role === 'developer' ? 'error.main' : 'primary.main',
              textTransform: 'capitalize',
            }}
          >
            {user ? user.full_name?.charAt(0) : ''}
          </Avatar>
          {!isMobile && user ? (
            <Typography variant="subtitle1" fontWeight="bold" textTransform="capitalize">
              {user.full_name}
            </Typography>
          ) : null}
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Fade
            {...TransitionProps}
            in={open}
            timeout={{
              appear: 0,
              enter: 300,
              exit: 150,
            }}
          >
            <Paper
              sx={{
                width: 290,
                minWidth: 240,
                maxWidth: 290,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 250,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Card>
                  <CardContent sx={{ px: 2.5, pt: 3 }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1.5}
                    >
                      <Grid item>
                        <Stack direction="row" spacing={1.25} alignItems="center">
                          <Avatar
                            alt="profile user"
                            sx={{
                              width: 32,
                              height: 32,
                              bgcolor: role === 'developer' ? 'error.main' : 'primary.main',
                            }}
                          >
                            {user ? `${user.full_name?.charAt(0)?.toUpperCase()}` : ''}
                          </Avatar>
                          {user ? (
                            <Stack>
                              <Typography variant="h6">{user.full_name}</Typography>
                              <Typography variant="body2" fontWeight="bold" color="textSecondary">
                                {user.email}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {role}
                              </Typography>
                            </Stack>
                          ) : null}
                        </Stack>
                      </Grid>
                      {user ? (
                        <Grid item xs={12}>
                          <Divider sx={{ mb: 2 }} />
                          <Select
                            disabled={loading}
                            fullWidth
                            name="user_language"
                            size="small"
                            id="edit_user_language"
                            value={user.user_language}
                            open={selectOpen}
                            onClose={() => setSelectOpen(false)}
                            onOpen={() => setSelectOpen(true)}
                            onChange={(e) =>
                              handleLanguageChange(e.target.value as ta_user_user_language)
                            }
                            startAdornment={
                              <InputAdornment position="start">
                                <PublicRoundedIcon />
                              </InputAdornment>
                            }
                          >
                            <MenuItem value="en">{t('settings.languages.en')}</MenuItem>
                            <MenuItem value="de">{t('settings.languages.de')}</MenuItem>
                            <MenuItem value="da">{t('settings.languages.dk')}</MenuItem>
                            <MenuItem value="fo">{t('settings.languages.fo')}</MenuItem>
                          </Select>
                        </Grid>
                      ) : null}
                      <Grid item xs={12}>
                        <Button
                          color="error"
                          fullWidth
                          variant="outlined"
                          startIcon={<LogoutRoundedIcon fontSize="large" />}
                          onClick={() => {
                            localStorage.clear()
                            sessionStorage.clear()
                            resetCaches() //reset apollo caches
                            logout(
                              window?.location?.origin
                                ? {
                                    logoutParams: { returnTo: window.location.origin },
                                    clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENTID || '',
                                  }
                                : {}
                            )
                          }}
                        >
                          {t('settings.logout')}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  )
}

export default Profile
